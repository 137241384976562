.auth-main{
    width: 100%;
    max-width: 700px;
  
    margin: 100px auto;
    padding: 40px 20px;
  
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  
  .auth-terms{
    font-size: 14.5px;
  
    margin-top: 0;
    margin-bottom: 40px;
  
    color: rgb(131, 131, 131);
  }
  
  .login-title {
    border-bottom: 1px solid rgb(225, 225, 225);
  
    padding-bottom: 10px;
  }
  .auth-container {
    display: flex;
    justify-content: space-between;
  }
  
  .auth-kora-logo{
    margin: auto 43%;
  }
  
  .auth-slogan{
    margin: 20px 50px;
    color: rgb(90, 90, 90);
  }
  
  .auth-container-section{
    flex: 1 1 50%;
    padding: 10px;
  }
  
  .auth-container-section:not(:last-child){
    border-right: 1px solid rgb(225, 225, 225);
  }
  
  .signupform-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .sign-in-btn{
    margin-top: 10px;
  
    border: none;
  
    color: black;
    /* background-color: white; */

    cursor: pointer;
  }

  .login-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px;

    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(155, 155, 155);

    height: 500px;
    width: 400px;
  }
  
  .login-form {
    margin-top: auto 100px;
    padding: auto 100px;
  }
  
  .login-text-and-password-input{
    width: 92%;
    padding: 10px;
    margin-bottom: 20px;
  
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .login-submit {
    display: inline;
  
    margin-left: 80px;
    width: 25%;
    padding: 10px;
  
    background-color: rgb(64, 64, 64);
    color: #fff;
  
    border: none;
    border-radius: 50px;
  
    cursor: pointer;

    transition: all 0.7s;
  }
  
  .login-submit:hover {
    background-color: black;
    color: #fff;
  
    transition: all 0.7s;
  }
  
  .login-error{
    color: red;
  }

  .no-account-container {
    display: flex;

    margin-top: 35px;
  }

  .no-accont-question{
    margin: 0px;
  }

  .create-one-btn{
    color: #1A8917;

    font-weight: 600;

    border: none;
    background: none;
  }

  .agreement{
    margin-top: 70px;
    text-align: center;
  }