.signup-modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    
    width: 566px;
    height: 807px;

    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(155, 155, 155);
}

.signup-form{
    margin: 0px 45px;
    
}

.auth-title {
    font-size: 32px;
    font-weight: 100;
    font-family: gt-super, Georgia, Cambria, "Times New Roman", Times, serif;
}

.already-accont-question{
    margin-left: 100px;
}

.have-account-sign-in-btn{
    color: #1A8917;
    
    font-weight: 600;
    
    border: none;
    background: none;
}

.error{
    color: red;
}