.hidden {
  display: none;
}

.nav-bar {
  /* margin: 15px auto; */
  /* padding-bottom: 30px; */

  border-bottom: solid 1px rgb(242,242,242) ;

  font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
}

.landing-nav-bar {
  /* margin: 15px auto; */
  padding-top: 20px;
  /* padding-bottom: 40px; */

  background-color: #FFC017;

  border-bottom: solid 1px rgb(0,0,0) ;

  font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
}

.nav-write-profile-btn{
  float: right;
  display: inline;
  
  margin-top: 15px;
  margin-right: 250px;
}

.landing-write-btn{

  text-decoration: none;

  color: #000;
}

.home-img-btn{
  max-width: 90px;
  margin-left: 150px;
  margin-bottom: 5px;

  text-decoration: none;
}

.landing-media-title{
  position: absolute;

  top: 30px;
  font-weight: 500;
  font-size: 35px;

  /* margin-bottom: 150px; */
  color: black;

  text-decoration: none;
}

.media-title{
  position: absolute;

  top: 15px;
  font-weight: 500;
  font-size: 35px;

  /* margin-bottom: 150px; */
  color: black;

  text-decoration: none;
}

.home-img-btn-container{

  display: inline;
  /* align-items: center; */
}

.sign-in-btn{
  background: none;

  margin-left: 20px;
}

.get-started-btn{
  padding: 8px 20px;
  margin-left: 20px;

  border-radius: 20px;
  border: none;

  color: white;
  background-color: black;

  cursor: pointer;
}

.new-post-btn{
  margin-right: 25px;
  /* margin-bottom: 5px; */

  text-decoration: none;

  color: rgb(107,107,107);
}

.new-post-btn:hover {
  color: rgb(90, 90, 90);
}

.create-new-icon {
  font-size: 20px;
}

.nav-profile-photo-btn{
  margin-top: 2px;
  max-width: 25px;
}

.profile-dropdown{
  position: absolute;
  right: 13%;
  top: 66.5px;

  margin: 0;
  padding: 5px;

  width: 100px;
  height: 120px;
  z-index: 2;

  border-radius: 5px 5% / 0 0 5px 5px;
  border: 1px solid rgb(225, 225, 225);
  border-top: 0;

  background-color: white;

  transition: all 0.7s;
}

.nav-profile-btn{
  border: none;
  background-color: white;
}

.nav-dropdown-sing-out{
  color: rgb(225, 225, 225);
}

.nav-dropdown-sing-out:hover{
  color: rgb(0, 0, 0);

  cursor: pointer;
}

@media (max-width: 1024px) {
  .profile-dropdown{
    position: absolute;
    right: 22%;
    top: 66.5px;
  
    margin: 0;
    padding: 5px;
  
    width: 100px;
    height: 120px;
    z-index: 2;
  
    border-radius: 5px 5% / 0 0 5px 5px;
    border: 1px solid rgb(225, 225, 225);
    border-top: 0;
  
    background-color: white;

    transition: all 0.7s;
  }
}