.comment-update-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 400px;
    height: 250px;

    padding: 15px;
}

.update-comment-text{
    width: 400px;
    height: 100px;
    margin-top: 25px;
}